<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppFormInputError from "@/Components/Shared/forms/AppFormInputError.vue";
import AppFormField from "@/Components/Shared/forms/AppFormField.vue";
import AppFormFieldLabel from "@/Components/Shared/forms/AppFormFieldLabel.vue";

import { defaultFormEmits, defaultInputProps } from "@/Utils/form";
import AppText from "@/Components/Shared/text/AppText.vue";
import { useAttrs } from "vue";

type InputSize = "small" | "regular";
type InputVariant = "fontLarge";

type Props = {
    modelValue?: string | number | boolean | undefined | Array<string | number>;
    size?: InputSize;
    variant?: InputVariant;
} & defaultInputProps;

const { modelValue = undefined, size = "regular", variant = undefined } = defineProps<Props>();

const emit = defineEmits(defaultFormEmits);

const classes: AntlerClasses<Props> = {
    base: "block w-full grow cursor-pointer font-medium rounded-md border-slate-300 hover:border-slate-400 text-base text-black focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm disabled:bg-gray-100 disabled:text-slate-700",
    variants: {
        size: {
            small: "min-h-[30px] py-1 pl-2 pr-7",
            regular: "min-h-[40px] py-2 pl-3 pr-7",
        },
        variant: {
            fontLarge: "sm:text-lg sm:leading-none",
        }
    },
};

const attrs = useAttrs();

const { aClass } = installAntlerComponent("field--select", {size, variant}, classes, attrs);

// methods
const handleChange = event => {
    emit("update:modelValue", event.target.value);
};
</script>

<template>
    <AppFormField :has-icon="!!icon">
        <AppFormFieldLabel
            v-if="label"
            :for="name"
            :required="required"
        >
            {{ label }}
        </AppFormFieldLabel>

        <div class="flex items-center">
            <select
                :id="name"
                :class="aClass()"
                :disabled="disabled"
                :name="name"
                @change="handleChange"
            >
                <slot />
            </select>
        </div>

        <AppFormInputError
            v-if="errors && errors.length"
            :errors="errors"
        />
        <!-- eslint-disable vue/no-v-html -->
        <AppText
            v-else-if="hint"
            class="mt-1"
            color="grayLight"
            size="small"
            v-html="hint"
        />
    </AppFormField>
</template>
